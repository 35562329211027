/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Form, Button, Container, Row, Col } from "react-bootstrap"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import stampImgDesk from "../../../images/PropertyManagement/stamp-img.png"
import stampImgTab from "../../../images/PropertyManagement/stamp-img.png"
import stampImgMob from "../../../images/PropertyManagement/stamp-img.png"
import { Link } from "@StarberryUtils"
import $ from "jquery"
import StampDutyCaclc from "../stampdutycalc/property-stampduty"
import { calculateMonthlyPayment } from "./mortgage"
import Modal from "react-bootstrap/Modal"
import MortageAdvisorForm from "../../../Components/forms/mortgage-advisor-form"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
  fadeInFromLeft,
  fadeInFromRight,
  imageZoom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
function percentage(a, b) {
  var c = (parseFloat(a) * parseFloat(b)) / 100
  return parseFloat(c)
}
// use project specific import here

// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        mortgageAdvice(publicationState: LIVE) {
          Content
          Image {
            alternativeText
            url
          }
          Image1 {
            alternativeText
            url
          }
        }
      }
    }
  `)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [isMortage, setIsMortage] = useState(true)
  const morgateRef = useRef(null)
  const stampRef = useRef(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    morgateRef.current.onclick = () => {
      setIsMortage(true)
    }
    stampRef.current.onclick = () => {
      setIsMortage(false)
    }
  }, [])

  // DO NOT DO ANY CHNAGES - START
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

  const prefix = ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState(0)
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [deposit, setDeposit] = useState(
    prefix + numberFormat(percentage(props.propertyPrice, 20))
  )
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [depositError, setDepError] = useState(false)
  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      // if(parseInt(filterNumber(deposit)) > parseInt(filterNumber(event.target.value))){
      //   setMonthlyPayment(0)
      // }
      // else {
      setPurchasePrice(prefix + numberFormat(val))
      if (parseInt(filterNumber(deposit)) && parseInt(val)) {
        let loan = parseInt(val) - parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      } else {
        setLoan(prefix + 0)
      }
      $(".btn-calculate").click()
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(event.target.value)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      )
      setShowerror(false)
      // }
    } else {
      setPurchasePrice("")
      setLoan()
      setMonthlyPayment(0)
    }
  }

  const handleclosing = () => {
    setShowModal(false)
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      // if(parseInt(filterNumber(purchasePrice)) === parseInt(filterNumber(event.target.value)) || parseInt(filterNumber(purchasePrice)) < parseInt(filterNumber(event.target.value))){
      //   setShowerror(true)
      //   setDeposit(prefix + numberFormat(d))
      // }
      // else {
      setDeposit(prefix + numberFormat(d))

      if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
        let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
        setLoan(prefix + numberFormat(loan2))
      } else {
        setLoan(prefix + 0)
      }
      $(".btn-calculate").click()
      var par = purchasePrice.replace("£", "").replace(/,/g, "")
      var dep = event.target.value.replace("£", "").replace(/,/g, "")
      var par1 = parseInt(par)
      var dep1 = parseInt(dep)
      if (par1 < dep1 || par1 === dep1) {
        setDepError(true)
        setMonthlyPayment(0)
      } else {
        setDepError(false)
        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(event.target.value)),
            duration
          )
        )
      }
      setShowerror(false)

      // }
    } else {
      setLoan("")
      setDeposit("")
      setMonthlyPayment(0)
    }
  }

  const handleModal = () => {
    setShowModal(true)
  }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
    $(".btn-calculate").click()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        event.target.value
      )
    )
  }

  const handleInterest = event => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
    $(".btn-calculate").click()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(event.target.value),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
    $(".btn-calculate").click()
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        getResult(interest, duration, loan)
      }
    }
  }

  useEffect(() => {
    $(".btn-calculate").click()
    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      )
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  useEffect(() => {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      let loan =
        parseInt(filterNumber(purchasePrice)) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }
    getResult(interest, duration, loan)
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }, [])
  // DO NOT DO ANY CHNAGES - END
  var monthly_payment = Math.round(monthlyPayment)
  const email = "team@locationlocation"

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="property-calculator"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="property-calc-main-head">
                    <h2 className="d-xl-none">Property Calculator</h2>
                  </div>
                  <div className="calculate-section custom-tab-block">
                    <div className="custom-tab-pane d-xl-none">
                      <ul>
                        <li
                          ref={morgateRef}
                          className={
                            isMortage ? "activate-tab tab-link" : "tab-link"
                          }
                        >
                          <span>Mortgage Calculator</span>
                        </li>
                        <li
                          ref={stampRef}
                          className={
                            !isMortage ? "activate-tab tab-link" : "tab-link"
                          }
                        >
                          <span>Stamp Duty</span>
                        </li>
                      </ul>
                    </div>
                    <div className="form mortgate-form">
                      {showerror && (
                        <div className="alert-error">
                          <p>Highlighted fields are required | invalid</p>
                        </div>
                      )}
                      {depositError && (
                        <div className="alert-error">
                          <p>
                            Please enter deposit amount less than property
                            amount.
                          </p>
                        </div>
                      )}
                      <div className="tab-data">
                        <motion.div
                          variants={fadeInFromLeft}
                          className={
                            isMortage
                              ? "tab-active tab-data-body tab-body-col-1"
                              : "tab-data-body tab-body-col-1"
                          }
                        >
                          <h3 className="d-none d-xl-block">
                            Mortgage Calculator
                          </h3>
                          <p>
                            A quick and easy way to calculate your monthly
                            mortgage payments. Simply enter the property price,
                            the amount or percentage of your deposit, the term
                            over which you intend to pay it off and the interest
                            rate.
                          </p>
                          <div className="form-property-calculator">
                            <Form
                              name="MortgageCalc"
                              noValidate
                              validated={validated}
                              onSubmit={handleSubmit}
                              autoComplete="off"
                              id="mortgage_frm"
                              method="post"
                              action="javascript:;"
                            >
                              <input type="hidden" name="bot-field" />
                              <div className="form-col">
                                <Form.Group controlId="purchase_price">
                                  <Form.Label>
                                    Property Purchase Price
                                  </Form.Label>
                                  <div className="form-addon-left form-border">
                                    <Form.Label className="form-control d-flex m-0 align-items-center">
                                      &#163; {purchasePrice}
                                    </Form.Label>
                                    {/* <Form.Control
             required
             type="text"
             name="purchase_price"
             value={purchasePrice}
             readOnly
             placeholder="Purchase Price"
             onChange={handlePrice}
           /> */}
                                    <span></span>
                                  </div>
                                </Form.Group>
                                <Form.Group controlId="deposit">
                                  <Form.Label>Deposit</Form.Label>
                                  <div className="form-addon-right form-border">
                                    <Form.Control
                                      required
                                      type="text"
                                      name="deposit_available"
                                      value={deposit}
                                      placeholder="Deposit Available"
                                      onChange={handleDeposit}
                                      maxLength={13}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="form-col">
                                <Form.Group controlId="duration">
                                  <Form.Label>Duration</Form.Label>
                                  <div className="form-addon-left form-border">
                                    <Form.Control
                                      as="select"
                                      required
                                      name="duration"
                                      value={duration}
                                      placeholder="Duration (Years)"
                                      onChange={handleDuration}
                                    >
                                      {durationOptions.map((value, key) => {
                                        return (
                                          <option value={value} key={key}>
                                            {value} Years
                                          </option>
                                        )
                                      })}
                                    </Form.Control>
                                    <span></span>
                                  </div>
                                </Form.Group>
                                <Form.Group controlId="interest_rate">
                                  <Form.Label>Interest Rate</Form.Label>
                                  <div className="form-addon-right form-border">
                                    <Form.Control
                                      required
                                      type="text"
                                      name="interest_rate"
                                      value={interest}
                                      placeholder="Interest Rate (%)"
                                      onChange={handleInterest}
                                      maxLength={4}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                              <Form.Group
                                controlId="loan_amount"
                                className="d-none"
                              >
                                <Form.Label>Loan Amount</Form.Label>
                                <div className="form-addon-left form-border readonly-loan">
                                  <Form.Control
                                    required
                                    type="text"
                                    name="loan_amount"
                                    value={loan}
                                    placeholder="Loan Amount"
                                    onChange={handleLoan}
                                    disabled="disabled"
                                  />
                                  <span></span>
                                </div>
                              </Form.Group>
                              <div className="form-col">
                                <Form.Group>
                                  <Form.Label>Monthly Repayments</Form.Label>
                                  <span className="mrp-amount">
                                    {currency}
                                    {numberFormat(monthly_payment)}
                                  </span>
                                </Form.Group>
                              </div>
                              {/* <p className="form-bottom-note">
                  Need more info? See our <Link href="/property-services/buy/buying-guide" className="btn-text-link">mortgage guide</Link> and <Link href="/property-services/buy/mortgage-calculator" className="btn-text-link">calculators.</Link> <a onClick={() => setShow(true)} className="btn-text-link" href="#">Click here</a> to get in touch with our mortgage advisor
                  </p> */}

                              <Button
                                type="submit"
                                id="linkid"
                                className="d-none btn btn-pink btn-calculate"
                              >
                                Calculate
                              </Button>
                            </Form>
                          </div>
                          {/* <div className="mort-advice-block">
                            <div>
                              <div className="img-bk">
                                <img
                                  alt="Mortgage calculator - Location Location"
                                  src={
                                    props.property_office_email ===
                                    "sn@locationlocation.com"
                                      ? data.glstrapi?.mortgageAdvice?.Image1
                                          ?.url
                                      : data.glstrapi?.mortgageAdvice?.Image
                                          ?.url
                                  }
                                />
                              </div>
                            </div>
                            <div className="para-bk">
                              <ReactMarkdown
                                source={data.glstrapi?.mortgageAdvice?.Content}
                                escapeHtml={false}
                              />
                              <a
                                className="btn btn-yellow yell"
                                onClick={() => handleModal()}
                              >
                                Help Me
                              </a>
                            </div>
                          </div> */}
                        </motion.div>
                        <motion.div variants={fadeInFromRight}>
                          <StampDutyCaclc
                            propertyValue={props.propertyPrice}
                            activecalc={isMortage}
                          />
                        </motion.div>
                      </div>
                      {show && (
                        <>
                          <Modal
                            show={show}
                            centered={true}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                          >
                            <Modal.Header
                              closeButton
                              className="contact-close-btn"
                            >
                              <Modal.Title className="w-100">
                                Get in touch with our mortgage advisor
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p></p>
                              <MortageAdvisorForm
                                propertyaddress={props.propertyaddress}
                                property_office_email={
                                  props.property_office_email
                                }
                                listing_id={props.listing_id}
                                branch={props.branch}
                              />
                            </Modal.Body>
                          </Modal>
                        </>
                      )}
                      {/* {result && (
         <div className="alert-success d-none">
           <p>
             Monthly Repayment: {currency}
             {numberFormat(monthly_payment)}
           </p>
         </div>
       )} */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </motion.section>
        )}
      </InView>

      {
        <>
          <Modal
            show={showModal}
            centered={true}
            onHide={handleclosing}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">Mortage Advice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p></p>
              <MortageAdvisorForm
                branch={props.branch}
                propertyPhone={props.propertyPhone}
                propertyaddress={props.propertyaddress}
                property_office_email={props.property_office_email}
                listing_id={props.listing_id}
              />
            </Modal.Body>
          </Modal>
        </>
      }
    </React.Fragment>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIPROP,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
