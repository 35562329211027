import React, { useState } from "react"
import { Row, Container } from "react-bootstrap"
import LocRatingMap from "../map/loc-rating-map"
import { MapMarkerInfoBox } from "../map/map-marker"

const LocatingMap = props => {
  // Get Map Details
  let mapItems = []

  let mapItem = {}
  mapItem["lat"] = props.Latitude
  mapItem["lng"] = props.Longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox(props.Title, mapMarkerImageShowFlag)
  // console.log( mapItemHtml );
  mapItem["html"] = mapItemHtml

  mapItems.push(mapItem)
  let id = props.id
  let type = props.type

  return (
    <div>
      <LocRatingMap data={mapItems} id={id} type={type} />
    </div>
  )
}

export default LocatingMap
