import React, { useState } from 'react'
import { Link } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from "@reach/router"
import Modal from "react-bootstrap/Modal"
import $ from 'jquery'
import SendFriendForm from '../../forms/sendfrnd-form'
import {ShareIcon} from '../../icons'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
}
    from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
const GETOFFICE=  gql`
query GetOfficeQuery ($Phone: String!){   
 ourOffices(where:{Office_Phone:$Phone}){
     Office_Email
     Office_Phone     
 }
}
`;

const SocialShare = (props) => {
	    const [Shareicons, setShareicons] = React.useState(false);
	    const location = useLocation();
    	const shareUrl = location.href
		const openShareicons = () => {
            setShareicons(!Shareicons)
	    }
	  const [show, setShow] = useState(false);

	  const handleClose = () => setShow(false);
      const handleShow = (event) => {   
        setShow(true);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });   
    }
      const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    const { loading, error, data } = useQuery(GETOFFICE, {variables: { Phone: props.property_office_phone }});
    if (loading) return <p className="d-none">Loading ...</p>;
    var officeEmail= ""
    if(data && data.ourOffices) {
      officeEmail = data.ourOffices[0]?.Office_Email;
    }
	    return (
	    	<>
		<a onClick={openShareicons}  href="javascript:void(0)" className="btn-share">
                  <ShareIcon /> 
                  {Shareicons &&
        <div className="hover-share">
            <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareUrl} className="my-share-button facebook-share">
                <FacebookIcon size={32} round={false} borderRadius={`10`} />
            </FacebookShareButton>
            <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareUrl} className="my-share-button twitter-share">
                <TwitterIcon size={32} round={false} borderRadius={`10`} />
            </TwitterShareButton>
            
            <EmailShareButton onClick={()=>handleShow('SendToFriend')} className="my-share-button email-share">
                <EmailIcon size={32} round={false} borderRadius={`10`} />
            </EmailShareButton>
            
            <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareUrl} className="my-share-button linked-share">
                <LinkedinIcon size={32} round={false} borderRadius={`10`} />
            </LinkedinShareButton>

            <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareUrl} className="my-share-button whatsapp-share">
                <WhatsappIcon size={32} round={false} borderRadius={`10`} />
            </WhatsappShareButton>
        </div>
    }
		</a>
          <Modal
              id="share_modal_mobile"
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
       		 dialogClassName="modal-90w modal-form"
            >
              <Modal.Header closeButton className="contact-close-btn memberPopup">
                <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0 text-uppercase">Share to a Friend</h6></Modal.Title>
              </Modal.Header>
              <p className="col-12 content_r-l mt-3 mb-0 text-center modal-text">We work with very good suppliers and have access to special offers from the banks. Please leave your contact details and we will be in touch shortly. We can help.</p>
              <Modal.Body>
			        <SendFriendForm  to_email_id={officeEmail} property_id={props.id} page_url={shareUrl} property_title={props.title ? props.title : ''} property_img={props.images ? props.images.url : ''} property_address={props.address_details ? props.address_details : ''} property_bedrooms={props.bedrooms ? props.bedrooms : ''} property_bathrooms={props.bathrooms ? props.bathrooms : ''} />
              </Modal.Body>
            </Modal>      
        </>
)

}
export default SocialShare
