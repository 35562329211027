import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
// import { markerImageSrc } from "./map-marker-image";
import { Scrollbars } from 'react-custom-scrollbars-2';

/* TODO: To be moved to env. or config. file */
import { useLocation } from "@reach/router"
import LoadExternalScript from "../utils/load-external-script";
import { markerImageSrc } from "./map-marker-image";
import classNames from 'classnames';
import { isIOS, isMobile, isMobileOnly, isTablet } from "react-device-detect"
const LocRatingMap = (props) => {
      // const location = useLocation();
      // const currentUrl = location.href
      // var Lat = props.latitude;
      // var Lng = props.longitude;
      // var iframe_url = "https://schools.locrating.com/html5/plugin/stations.aspx?&lat="+Lat+"&lng="+Lng+"&zoom=15&maxstations=6&titlecolor=%23515151&subtitlecolor=%23515151&distancecolor=%23515151&id=transportlist_frame&fullpath="+currentUrl+"";
      // return (
      //   <>
      //   <Scrollbars style={{ width: '', height: (props.LargeMonitor)?290:(props.ClientWidth)?410:290 }}>
      //   <iframe id="transportlist_frame" src={iframe_url}></iframe>
      //   </Scrollbars>
      //   </>
      // )
      useEffect(()=>{
        setTimeout(function(){
        try{window.loadLocratingPlugin({id:`map_${props.id}`,maxstations:"6",lat: props.latitude, lng : props.longitude, type:'stationslist',distancecolor:'#515151',html:'<div id="asasasasas"></div>'});}catch (err) {}; 
      }, 2000);
  },[])   
  return (
    isMobileOnly ? 
    <div id={`map_${props.id}`} className={"map_cnt"}></div> :
    <Scrollbars style={{ width: '', height: (props.LargeMonitor)?290:(props.ClientWidth)?410:290 }}>
    <div id={`map_${props.id}`} className={"map_cnt"}></div>
    </Scrollbars>
  )

}

export default LocRatingMap;