import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from "./elements/input"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
import CheckboxField from "./elements/checkbox"
import RadioField from "./elements/radio"
import ButtonField from "./elements/button"
import HtmlBox from "./elements/html"
import ReCaptchaBox from "./elements/recaptcha"
import { postFormData } from "./api/Api"
import BookingSteps from "../../Components/ValuationSteps/Steps/Book-a-view-Steps"
import axios from "axios"
import * as qs from "query-string"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "@StarberryUtils"
import $ from "jquery"
import { useLocation } from "@reach/router"
function MySimpleForm(props) {
  const location = useLocation()
  const currentUrl = location.href
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)

  const [formvalues, setFormvalues] = useState("")

  const [token, setToken] = useState("")

  const myRef = React.createRef()

  const recaptchaRef = React.createRef()

  const fields = [
    {
      element: "config",
      formname: "Book a Viewing",
      form_type: "bookaviewing",
      error_text: "Highlighted fields are required",
      success_text:
        "Thank you for contacting us. A member of our team will contact you shortly.",
      email_temp_user: "book_user",
      email_temp_admin: "book_admin",
      email_subject_user: "Viewing Request",
      email_subject_admin: props.subject,
      email_server_func: "book_a_viewing",
      event_tracking: "book_a_viewing",
      page_url: "/general-enquiry",
    },
    {
      grpmd: "12",
      name: "Submit",
      type: "submit",
      element: "button",
      value: "submit",
      class: "w-md-100 mtop-btn  btn btn-yellow d-none",
      labelClass: "content_b-18",
    },
    {
      text:
        'By clicking Submit, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-and-cookie-policy/">Privacy &amp; Cookie Policy</a>',
      element: "html",
      class: "terms d-none",
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef,
    },
  ]

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    if (token !== "") {
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token

        let formData = new FormData()
        formvalues["extra"] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name,
        })
        formvalues["name"] = formvalues.name
        // formvalues["apfEmail"] = props.apfEmail
        
        formvalues["email_subject_user"] = fields[0].email_subject_user
        formvalues["email_subject_admin"] = fields[0].email_subject_admin

        formData.append("data", JSON.stringify(formvalues))

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })

          // lets send mail
          // const axiosOptions_email = {
          //   url: '/.netlify/functions/server/'+fields[0].email_server_func,
          //   method: "post",
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          //   data: qs.stringify(formvalues),
          // }

          // axios(axiosOptions_email)
          //   .then(response => {
          //     console.log('mail sent!')
          //   })
          //   .catch(err =>
          //     console.log(err)
          //   );
        })

        // formvalues['g-recaptcha-response'] = token;

        // const axiosOptions_netlify = {
        //   url: fields[0].page_url,
        //   method: "post",
        //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
        //   data: qs.stringify(formvalues),
        // }

        // axios(axiosOptions_netlify)
        //   .then(response => {
        //     console.log('data stored')
        //   })
        //   .catch(err =>
        //     console.log(err)
        //   );

        // tracking event
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "formSubmit",
          formType: "form-" + fields[0].event_tracking,
          formId: "form-" + fields[0].event_tracking,
          formName: fields[0].formname,
          formLabel: fields[0].formname,
        })

        setShowerror(false)
        setThankyou(true)
        $(".valuationstep1").hide()
        $(".valuationstep2").hide()
        $(".valuationintrostep3").hide()
        $(".dateandtimesection.dateandtimesectionintro").hide()
        $(".valuationthankyou.valuationintrothankyou").show()
        $("html, body").animate({ scrollTop: 0 }, 500)
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    // console.log("captcha verified")
    setToken(token)
  }

  const handleSubmit = event => {
    var selectedaddress = $("input[name=address]").val()
    $("input[name=selected_address]").val(selectedaddress)
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      event.preventDefault()

      const formsdata = event.target
      const json = {}
      Object.keys(formsdata).map(
        key =>
          (json[formsdata[key].name] = formsdata[key].checked
            ? "yes"
            : formsdata[key].value)
      )

      json["email_temp_user"] = fields[0].email_temp_user
      json["email_temp_admin"] = fields[0].email_temp_admin
      json["formname"] = fields[0].formname
      json["g-recaptcha-response"] = token
      // json["apfEmail"] = props.apfEmail

      setFormvalues(json)
      recaptchaRef.current.execute()

      setValidated(false)

      // reset form
      const form = event.target
      form.reset()
    }
  }

  const [Bookingback, setBookingback] = useState("")

  const handlebackClick = value => {
    setBookingback(value)
  }
  //console.log('Bookingback',Bookingback)
  return (
    <div className="form stbform" id="contactform">
      <div ref={myRef} />
      <div className="steps-error"></div>
      {showerror && (
        <div className="alert-error">
          <p>{fields[0].error_text}</p>
        </div>
      )}

      {showthankyou && (
        <div className="valuation-success">
          <div className="tab-box tab-box2 card-box">
            <div className="tab-box-form">
              <div className="cardbox-content">
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
                  <p>
                      Your request has been sent to our team.
                      We will contact you shortly to confirm your request and
                      explain the booking process in more detail.
                  </p>
                </ScrollAnimation>
                <div className="what-to-do-text">
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <h6>What to do next?</h6>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  </ScrollAnimation>
                </div>
                <div className="tab-outline-buttons btn-outline-group2">
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <Link
                      className="btn btn-outline"
                      to="/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london"
                    >
                      <span>Find a Property</span>
                    </Link>
                  </ScrollAnimation>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <Link className="btn btn-outline mb-0" to="/contact">
                      <span>Contact Us</span>
                    </Link>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Form
        className="contact-form form-w-label"
        name={fields[0].formname}
        action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="property_url" value={currentUrl} />
        <input type="hidden" name="repeatId" value={props.repeatId} />
        <input type="hidden" name="apfEmail" value={props.apfEmail} />
        <input type="hidden" name="property_price" value={props.property_price} />
        <input type="hidden" name="property_type" value={props.property_type} />
        <input type="hidden" name="Bgimg" value={props.Bgimg} />
        <input type="hidden" name="property_address" value={props.property_address} />
        
        
        <input type="hidden" name="bot-field" />
         {/* {props.apfEmail} */}
        {/* {JSON.stringify(props.property_address)} */}
        {/* {props.repeatId} */}
        <BookingSteps
          Booking={props.Booking}
          handlebackClick={handlebackClick}
        />
        {fields.map((field, index) => {
          if ("input" === field.element) {
            return (
              <InputField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                type={field.type}
                fieldClass={field.class}
                placeholder={field.placeholder}
                label={field.label}
                labelClass={field.labelClass}
                required={field.required}
                key={`${field.element}~${index}`}
                pattern={field.patternchk}
                handlechange={handlechange}
              />
            )
          }
          if ("select" === field.element) {
            return (
              <SelectField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                required={field.required}
                fieldClass={field.class}
                placeholder={field.placeholder}
                values={field.values}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
                componentprops={props}
              />
            )
          }
          if ("textarea" === field.element) {
            return (
              <TextAreaField
                name={field.name}
                grpmd={field.grpmd}
                ref={field.ref}
                rows={field.rows}
                fieldClass={field.class}
                label={field.label}
                labelClass={field.labelClass}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.element}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("checkbox" === field.element) {
            return (
              <CheckboxField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
              />
            )
          }
          if ("radio" === field.element) {
            return (
              <RadioField
                name={field.name}
                ref={field.ref}
                value={field.value}
                fieldClass={field.class}
                checked={field.checked}
                placeholder={field.placeholder}
                required={field.required}
                key={`${field.name}~${index}`}
                handlechange={handlechange}
                lastchild={field.lastchild}
              />
            )
          }
          if ("html" === field.element) {
            return (
              <HtmlBox
                text={field.text}
                fieldClass={field.class}
                key={`${field.element}~${index}`}
              />
            )
          }
          if ("captcha" === field.element) {
            return (
              <ReCaptchaBox
                fieldClass={field.class}
                captRef={field.captchaRef}
                key={`${field.element}~${index}`}
                handleonVerify={handleonVerify}
              />
            )
          }
          if ("button" === field.element) {
            return (
              <ButtonField
                name={field.name}
                fieldClass={field.class}
                type={field.type}
                value={field.value}
                key={`${field.element}~${index}`}
              />
            )
          }
        })}
      </Form>
    </div>
  )
}

const ContactForm = props => (
  <MySimpleForm 
  to_email_id={props.to_email_id}
   Booking={props.Booking}
   property_price={props.property_price}
   subject={props.subject}
                      property_type={props.property_type}
                      Bgimg={props.Bgimg}
                      property_address={props.property_address}
                      apfEmail={props.apfEmail} 
                      repeatId={props.repeatId}
    />
)

export default ContactForm