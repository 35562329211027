import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./ContactTeam.scss"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Static/buyer-report-bottom-d.jpg"
import SearchBottomImgTablet from "../../../images/Static/contact-team-bottom-t.jpg"
import SearchBottomImgMobile from "../../../images/Static/contact-team-bottom-m.jpg"
import MortageAdvisorForm from "../../../Components/forms/mortgage-advisor-form"
import Modal from "react-bootstrap/Modal"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import PlayVideo from "../../Play/PlayVideo"
const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const PropertyReport = props => {
  const bookClick = value => {
    props.handleBookClick2(value)
  }
  const [isPlay, setPlay] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        propertyReportModule {
          Content
          Title
          Video_Url
          Image {
            alternativeText
            url
          }
        }
      }
    }
  `)
  const [showModal, setShowModal] = useState(false)

  const viewReport = sessionStorage.getItem("spriftlink")
  const reportResult = viewReport?.startsWith("https://sprift.com/");

  const handleclosing = () => {
    setShowModal(false)
  }
  const handleModal = () => {
    setShowModal(true)
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module full propertyReport"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block buyers-report">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  <div>
                    <picture>
                      <source
                        media="(min-width:992px)"
                        srcSet={data.glstrapi?.propertyReportModule?.Image?.url}
                      />
                      <source
                        media="(min-width:768px)"
                        srcSet={data.glstrapi?.propertyReportModule?.Image?.url}
                      />
                      <img
                        src={data.glstrapi?.propertyReportModule?.Image?.url}
                        alt="Take the stress out of moving home -  Location Location"
                      />
                    </picture>
                    {data.glstrapi?.propertyReportModule?.Video_Url && (
                      <strong
                        className="video-arrow video-arrow-localarea"
                        onClick={e => {
                          setPlay(true)
                        }}
                      >
                        <i className="icon-video-white"></i>
                      </strong>
                    )}
                  </div>
                  <PlayVideo
                    isOpen={isPlay}
                    isCloseFunction={setPlay}
                    videoId={
                      props.property_type === "sales"
                        ? data.glstrapi?.propertyReportModule?.Video_Url
                        : "https://youtu.be/1YqthouGZdk"
                    }
                    isAutoPlay={1}
                  />
                </motion.div>
                <div
                  // animateIn="fadeInUp"
                  // animateOnce="true"
                  className="search-bottom-content"
                >
                  <motion.h3 variants={contentItemText}>
                    {/* {data.glstrapi?.propertyReportModule?.Title} */}
                    Numbers not quite <span class="yellow-with-font">adding up? </span> 
                  </motion.h3>
                  <motion.div variants={contentItemText}>
                     <p>Why not speak to an impartial mortgage advisor for <a onClick={() => handleModal()} href="javascript:;" className="atag">free initial advice</a>.
                     <ul>
                    <li><span >Find the best mortgage deal for your situation</span></li>
                    <li><span>Improve your buyer credibility</span></li>
                    <li><span>Speed up the buying process</span></li>
                    </ul>
                      </p> 
                   
                    {/* <ReactMarkdown
                      source={data.glstrapi?.propertyReportModule?.Content}
                      escapeHtml={false}
                    /> */}
                  </motion.div>
                  <motion.div variants={contentItemText}>
                    <a
                      className="btn btn-yellow black"
                      onClick={() => handleModal()}
                      href="javascript:;"
                    >
                      Help Me
                    </a>
                    {/* {reportResult == true &&  (
                      <a 
                      className="btn btn-yellow black"
                      href={viewReport}
                      target='_blank'
                       >
                      {props.property_type === "sales"
                        ? "Download Buyers Report"
                        : "Download Residents Report"}
                      </a>
                      )
                      } */}
                  </motion.div>
                </div>
              </div>
            </div>
          </motion.section>
        )}
      </InView>

      <Modal
            show={showModal}
            centered={true}
            onHide={handleclosing}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
          >
            <Modal.Header closeButton className="contact-close-btn">
              <Modal.Title className="w-100">Mortage Advice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p></p>
              <MortageAdvisorForm
                branch={props.branch}
                propertyPhone={props.propertyPhone}
                propertyaddress={props.propertyaddress}
                property_office_email={props.property_office_email}
                listing_id={props.listing_id}
              />
            </Modal.Body>
          </Modal>

    </React.Fragment>
  )
}
export default PropertyReport
