import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
import { markerImageSrc } from "./map-marker-image";
import { useLocation } from "@reach/router"
// import { useLocation } from "@reach/router"
import LoadExternalScript from "../utils/load-external-script";
// import { markerImageSrc } from "./map-marker-image";
import classNames from 'classnames';
const LocRatingMap = (props) => {
      // const location = useLocation();
      // const currentUrl = location.href
      // var Lat = props.Latitude;
      // var Lng = props.Longitude;
      // var iframe_url = "https://schools.locrating.com/html5/plugin/broadband.aspx?&lat="+Lat+"&lng="+Lng+"&zoom=15&showmap=false&fontcolor=black&hideareaname=true&fontfamily='MundialRegular'&id=broadband_frame&fullpath="+currentUrl+"";
      // return (
      //   <>
      //   <iframe id="broadband_frame" src={iframe_url}></iframe>
      //   <div id={`broadband`} className={"map_cnt"}></div>
      //   </>
      // )
      useEffect(()=>{
        setTimeout(function(){
        try{window.loadLocratingPlugin({id:`broadband`,lat: props.Latitude, lng : props.Longitude, type:'broadband'});}catch (err) {}; 
      }, 2000);
  },[])   
  return (
    <div id={`broadband`} className={"map_cnt"}></div>
  )

}

export default LocRatingMap;