import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap"
import "./ContactTeam.scss"
import ReactMarkdown from "react-markdown/with-html"
import {
  inViewOptions,
  easeFunc,
  containerVariants,
  delayItemsFromBottom,
} from "../../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Static/buyer-report-bottom-d.jpg"
import SearchBottomImgTablet from "../../../images/Static/contact-team-bottom-t.jpg"
import SearchBottomImgMobile from "../../../images/Static/contact-team-bottom-m.jpg"
import { useStaticQuery, graphql } from "gatsby"
import Rent from "../../../images/Search/rent.png"
import VendorSell from "../../../images/Search/Vendors-Sell.jpg"

const contentItemText = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      ...easeFunc,
      duration: 0.6,
    },
  },
}
const BuyerReport = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        bookViewModule {
          Title
          Content
          Image {
            alternativeText
            url
          }
        }
      }
    }
  `)
  const bookClick = value => {
    props.handleBookClick2(value)
  }

  return (
    <React.Fragment>
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="contact-team bottom-team-module full"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <div className="container-custom">
              <div className="search-bottom-block buyers-report">
                <motion.div
                  className="search-bottom-image"
                  variants={delayItemsFromBottom}
                >
                  {props.propertyType === "lettings" ? (
                    <div>
                      <picture>
                        <source media="(min-width:992px)" srcSet={Rent} />
                        <source media="(min-width:768px)" srcSet={Rent} />
                        <img
                          src={Rent}
                          alt="Rent property -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : props.propertyType === "sales" ? (
                    <div>
                      <picture>
                        <source media="(min-width:992px)" srcSet={VendorSell} />
                        <source media="(min-width:768px)" srcSet={VendorSell} />
                        <img
                          src={VendorSell}
                          alt="Vendor property -  Location Location"
                        />
                      </picture>
                    </div>
                  ) : (
                    <div>
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={data.glstrapi?.bookViewModule?.Image?.url}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={data.glstrapi?.bookViewModule?.Image?.url}
                        />
                        <img
                          src={data.glstrapi?.bookViewModule?.Image?.url}
                          alt="Take the stress out of moving home -  Location Location"
                        />
                      </picture>
                    </div>
                  )}
                </motion.div>
                {props.propertyType === "lettings" ? (
                  <div className="search-bottom-content">
                    <motion.h3 variants={contentItemText}>
                      Join the Neighbourhood.
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      We care about our residents just as much as we do our
                      landlords. Making sure that you feel at home when renting,
                      is at the top of our priority list. Register with us and
                      experience the very best in service and advice.
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <Link
                        className="btn btn-yellow"
                        to="/property-services/sell/property-valuation/"
                      >
                        Book a Valuation
                      </Link>
                    </motion.div>
                  </div>
                ) : props.propertyType === "sales" ? (
                  <div className="search-bottom-content sell-page">
                    <motion.h3 variants={contentItemText}>
                      What's your home worth now?
                    </motion.h3>
                    <motion.p variants={contentItemText}>
                      Book a meeting with one of our local property marketing
                      experts. All valuations are complimentary and provided
                      with no obligation on you to use our services. We’re good
                      like that.
                    </motion.p>
                    <motion.div variants={contentItemText}>
                      <Link
                        className="btn btn-yellow"
                        to="/property-services/sell/property-valuation/"
                      >
                        Book a Valuation
                      </Link>
                    </motion.div>
                  </div>
                ) : (
                  <div
                    // animateIn="fadeInUp"
                    // animateOnce="true"
                    className="search-bottom-content"
                  >
                    <motion.h3 variants={contentItemText}>
                      {data.glstrapi?.bookViewModule?.Title}
                    </motion.h3>
                    <motion.div variants={contentItemText}>
                      <ReactMarkdown
                        source={data.glstrapi?.bookViewModule?.Content}
                        escapeHtml={false}
                      />
                    </motion.div>
                    <motion.div variants={contentItemText}>
                      <Link
                        to="/property-services/sell/property-valuation/"
                        className="btn btn-yellow"
                      >
                        Book a Valuation
                      </Link>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </motion.section>
        )}
      </InView>
    </React.Fragment>
  )
}
export default BuyerReport
