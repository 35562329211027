// import { Link } from "gatsby";
import React, { useState, useEffect } from "react"
import { Link } from "@StarberryUtils"
import {
  Button,
  Form,
  Container,
  FormControl,
  Row,
  InputGroup,
  Col,
} from "react-bootstrap"
import "./Header.scss"
import Logo from "../../images/locationl-logo.svg"
import { BackArrow } from "../../Components/icons"
import userImgDesk from "../../images/Static/stamp-img.png"
import userImgTab from "../../images/Static/stamp-img.png"
import userImgMob from "../../images/Static/stamp-img.png"
import MenuData from "./MenuData"
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery"
import { navigate } from "@reach/router"
import Helmet from "react-helmet"
const Header = props => {
  useEffect(() => {
    $(".menubar-desktop .submenu").each(function (i, el) {
      // Remove white space
      var html = $(el).html().replace(/\s+/, "")
      // Check if element is :empty or length of html is zero
      if ($(el).is(":empty") || html.length == 0) {
        $(el).remove()
      } else {
        $(el).parent().addClass("has-submenu")
      }
    })
  })

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(sort: "Sorting:asc") {
          id
          Link_Type
          Label
          Primary_URL
          Secondary_URL
          Topmenu
          Template
          Main_Parent {
            id
            Label
            Primary_URL
          }
          Sub_Parent {
            id
            Label
            Primary_URL
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)
  const [state, setState] = useState([
    {
      name: "Marketing",

      isActive: false,
    },
    {
      name: "Search",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "Services",
      submenu: [
        {
          name: "Buy",
          submenu: [
            "Buying Guide",
            "Property Calculator",
            "Register for Property Alertes",
          ],
          isActive: false,
        },
        {
          name: "Sell",
          submenu: [
            "Why sell with LL?",
            "Selling Guide",
            "Property Valuation",
            "Sales Progression",
            "Property Launch",
            "Black Book",
          ],

          isActive: false,
        },
        {
          name: "Rent",
          submenu: [
            "Renting with LL",
            "Renting Guide",
            "Tenant Charges",
            "Register for Property Alerts",
          ],

          isActive: false,
        },
        {
          name: "Landlords",
          submenu: [
            "Letting with LL",
            "Selling Guide",
            "Lettings Advice/Guide",
            "Landlord Fees",
            "Property Management",
          ],

          isActive: false,
        },
        {
          name: "New Homes",
          submenu: ["Land & New Homes", "Case Studies"],

          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Why?",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "People",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
    {
      name: "Contact",
      // submenu: [
      //   {
      //     name: "Buy",
      //     isActive: false,
      //   },
      //   {
      //     name: "Sell",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Rent",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "Landlords",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      //   {
      //     name: "New Homes",
      //     submenu: [
      //       "Why sell with LL?",
      //       "Selling Guide",
      //       "Property Valuation",
      //       "Sales Progression",
      //       "Property Launch",
      //       "Black Book",
      //     ],

      //     isActive: false,
      //   },
      // ],
      isActive: false,
    },
  ])

  console.log(props, "popopo")

  const openMenu = () => {
    setOpen(!open)
  }

  const closeMenu = () => {
    setOpen(false)
  }

  const handlerOpenMenu = index => {
    let newState = [...state]
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    let isActive = a[index].isActive
    a[index].isActive = !isActive
    setState(a)
  }

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation()
    let newState = [...state]
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false }
      } else {
        return item
      }
    })
    newState[index].submenu = a
    let isActive = newState[index].submenu[i].isActive
    newState[index].submenu[i].isActive = !isActive
    setState(newState)
  }
  var menu_data = data.glstrapi.allMenus
  // search results page navigation
  useEffect(() => {
    let url = "/"
    $(".sales_btn").click(function () {
      var searcValue = $(".search_field_text").val()
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-stoke-newington-and-hackney-and-north-london-and-east-london/"
      }
      navigate("/property/for-sale/" + url)
    })

    $(".rent_btn").click(function () {
      var searcValue = $(".search_field_text").val()
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-stoke-newington-and-hackney-and-north-london-and-east-london/"
      }
      navigate("/property/to-rent/" + url)
    })
  }, [])
  const backClick = value => {
    props.handlebackClick(value)
  }
  const backClick2 = value => {
    props.handlebackClick2(value)
  }
  return (
    <React.Fragment>
      <header className={open ? "open-navigation header-fix" : "header-fix"}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width; initial-scale=1.0, maximum-scale=1.0, user-scalabale=0"
          />
        </Helmet>
        <Container className="header-padding-mob container-header">
          <div className="menudesktop">
            <div className="logo">
              <div className="logo-mobile">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
              <div className="logo-tab">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
              <div className="logo-desktop">
                <Link to="/">
                  <img alt="Logo" src={Logo} />
                </Link>
              </div>
            </div>
            <div className="menu-icon">
              {/* <button type="button" onClick={openMenu}>
                <i className="hamberger-black"></i>
              </button> */}
              <div onClick={openMenu} id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="menu-bar ">
              {/* <div className="btn-close-menu">
                <button type="button" onClick={closeMenu} className="close-btn">
                  <i className="icon-close"></i>
                </button>
              </div> */}
              <div className="menu-nav">
                <ul className="main-menu-list">
                  {menu_data.map((item, i) => (
                    <MenuData
                      key={i}
                      index={i}
                      handlerOpenMenu={handlerOpenMenu}
                      handlerOpenSubMenu={handlerOpenSubMenu}
                      item={item}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="menubar-desktop">
              <div className="stepsheader">
                <div className="nav-links">
                  <li className="gobackVal bookingheadermenu">
                    {props.bookslide == "" ? (
                      <a onClick={() => backClick("BackView")}>
                        <BackArrow />
                        Go back
                      </a>
                    ) : (
                      <a onClick={() => backClick2("BackView2")}>
                        <BackArrow />
                        Go back
                      </a>
                    )}
                  </li>
                  <li>
                    <div className="user-data">
                      <div className="banner-user-details">
                        {/* <div className="banner-user-img">
          <picture>
            <source media="(min-width:992px)" srcSet={userImgDesk} />
            <source media="(min-width:768px)" srcSet={userImgTab} />
            <img src={userImgMob} alt=""/>

          </picture>
          </div> */}
                        <div className="banner-user-content">
                          <span className="name">
                            {/* <a className="val-chat">Live Chat</a> or */}
                            call:{" "}
                            <a href={`tel:${props.telephone}`}>
                              {props.telephone}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </header>
    </React.Fragment>
  )
}

export default Header
