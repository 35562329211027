import { Link } from "@StarberryUtils"
import React, { useEffect, useState, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap"
import "./Steps.scss"
import CalendarBlock from "../CalendarBlock/CalendarBlock"
import userImgDesk from "../../../images/Static/stamp-img.png"
import userImgTab from "../../../images/Static/stamp-img.png"
import userImgMob from "../../../images/Static/stamp-img.png"
import { BackArrow } from "../../../Components/icons"
import loadable from "@loadable/component"
import $ from "jquery"
import AddressSearch from "react-loqate"

const AddressSearchInput = props => {
  return (
    <div className="custom-float">
      <input
        name="address"
        placeholder="Start typing your address"
        autocomplete="off"
        type="text"
        id="address"
        class="form-control"
        {...props}
        value={props.addressLabel}
        required="true"
      />
    </div>
  )
}

const Steps = props => {
  const BookingType = props.Booking
  const [key, setKey] = useState("your-details")
  const [tabdisable, settTabdisable] = useState(true)
  const [booktype, setBooktype] = useState(BookingType)
  const [addressBuildingName, setAddressBuildingName] = useState("")
  const [addressBuildingNumber, setAddressBuildingNumber] = useState("")
  const [addressPostalCode, setAddressPostalCode] = useState("")
  const [addressSubBuilding, setAddressSubBuilding] = useState("")
  const [addressStreet, setAddressStreet] = useState("")

  $(".valuationstep2 .nav-tabs .nav-item.disabled").show()
  $(".defaultvaluationintro").hide()
  const Validatestep1 = () => {
    setAddressBuildingName($("input[name=addressBuildingName]").val())
    setAddressBuildingNumber($("input[name=addressBuildingNumber]").val())
    setAddressPostalCode($("input[name=addressPostalCode]").val())
    setAddressSubBuilding($("input[name=addressSubBuilding]").val())
    setAddressStreet($("input[name=addressStreet]").val())
    var valaddress = $("input[name=address]").val()
    if (valaddress == "") {
      $(".address-group").addClass("field-invalid")
      $(".steps-error").html(
        "<div class='alert-error'><p>Please enter property address</p></div>"
      )
      $("html, body").animate(
        {
          scrollTop: $(".steps-error").offset().top - 120,
        },
        1500
      )
    } else {
      setKey("your-details")
      $(".address-group").removeClass("field-invalid")
      $(".steps-error").html("")
      $(".valuationstep1").hide()
      $(".valuationstep2").show()
      $(".defaultvaluationintro").hide()
    }
  }
  const handleAddress = address => {
    document.getElementById("address").value = address.Label.replace(
      /\n/g,
      ", "
    )
    document.getElementById("addressBuildingName").value = address.BuildingName
    document.getElementById("addressBuildingNumber").value =
      address.BuildingNumber
    document.getElementById("addressPostalCode").value = address.PostalCode
    document.getElementById("addressSubBuilding").value = address.SubBuilding
    document.getElementById("addressStreet").value = address.Street
    $(".address-group").removeClass("field-invalid")
  }
  const Validatestep2 = () => {
    setAddressBuildingName($("input[name=addressBuildingName]").val())
    setAddressBuildingNumber($("input[name=addressBuildingNumber]").val())
    setAddressPostalCode($("input[name=addressPostalCode]").val())
    setAddressSubBuilding($("input[name=addressSubBuilding]").val())
    setAddressStreet($("input[name=addressStreet]").val())
    var valaddress = $("input[name=address]").val()

    var valname = $("input[name=name]").val()
    var vallname = $("input[name=lname]").val()
    var valemail = $("input[name=email]").val()
    var valtel = $("input[name=telephone]").val()
    var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    var telvalidation = false
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    var emailvalidation = false
    var valaddressvalidation = false

    if (valaddress == "") {
      $(".address-group").addClass("field-invalid")
      $(".steps-error").html(
        "<div class='alert-error'><p>Please enter property address</p></div>"
      )
      valaddressvalidation = false
    } else {
      valaddressvalidation = true
    }
    if (valemail.match(mailformat)) {
      emailvalidation = true
    } else {
      emailvalidation = false
    }

    if (valtel.match(phoneNum)) {
      telvalidation = true
    } else {
      telvalidation = false
    }
    if (
      valname &&
      vallname &&
      valemail &&
      valtel &&
      emailvalidation == true &&
      valaddressvalidation === true
    ) {
      settTabdisable(false)

      $(".stepsheader").removeClass("steponegoback")
      $(".stepsheader").addClass("steptwogoback")
      $(".valuationstep2.valuationintrostep2").hide()
      $(".valuationstep1.valuationintrostep1").hide()
      $(".valuationstep3.valuationintrostep3 h1").html(
        "Thanks " + valname + ". Now select your preferred time and date."
      )
      $(".valuationstep3.valuationintrostep3").show()
      setKey("date-time")
      $(".steps-error").html("")
      $("html, body").animate({ scrollTop: 0 }, 1500)
    } else {
      $(".valuationform form").addClass(" was-validated")
      $(".steps-error").html(
        "<div class='alert-error'><p>Highlighted fields are required</p></div>"
      )
      $("html, body").animate(
        {
          scrollTop: $(".steps-error").offset().top - 120,
        },
        1500
      )
    }
  }
  const handleTabChange = k => {
    setKey(k)
    if (key === "your-details") {
      $(".valuationstep1.valuationintrostep1").hide()
      $(".valuationstep3.valuationintrostep3").show()
      $(".stepsheader").removeClass("steponegoback")
      $(".stepsheader").addClass("steptwogoback")
    }
    if (key === "date-time") {
      $(".valuationstep1.valuationintrostep1").show()
      $(".valuationstep3.valuationintrostep3").hide()
      $(".stepsheader").removeClass("steptwogoback")
      $(".stepsheader").addClass("steponegoback")
    }
  }

  const backClick = value => {
    alert("test")
    props.handlebackClick(value)
  }

  return (
    <React.Fragment>
      <div className={`valuationstep1`}>
        <input
          id="addressBuildingName"
          type="hidden"
          name="addressBuildingName"
        />
        <input
          id="addressBuildingNumber"
          type="hidden"
          name="addressBuildingNumber"
        />
        <input id="addressPostalCode" type="hidden" name="addressPostalCode" />
        <input
          id="addressSubBuilding"
          type="hidden"
          name="addressSubBuilding"
        />
        <input id="addressStreet" type="hidden" name="addressStreet" />
        <div className="tab-box tab-box2">
          <Tabs
            activeKey={key}
            onSelect={k => handleTabChange(k)}
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="your-details"
              title="Your Details"
              disabled={tabdisable}
            >
              <div className="tab-box-form form-bk-details">
                <div className="tab-outline-buttons d-none">
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeIn"
                  >
                    <Link className="btn btn-outline" href="#" target="_blank">
                      <i className="icon-facebook-form"></i>{" "}
                      <span>Continue with Facebook</span>
                    </Link>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateOnce={true}
                    duration={0.5}
                    animateIn="fadeIn"
                  >
                    <Link className="btn btn-outline" href="#" target="_blank">
                      <i className="icon-google-form"></i>{" "}
                      <span>Continue with Google</span>
                    </Link>
                  </ScrollAnimation>
                </div>
                <div className="tab-form-head d-none">
                  <span>or fill out your details</span>
                </div>
                <div className="form-w-label">
                  <Form.Group>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      required
                      type="text"
                      name="name"
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      required
                      type="text"
                      name="lname"
                      placeholder=""
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      required
                      type="email"
                      name="email"
                      placeholder=""
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Telephone Number*</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      name="telephone"
                      required
                      type="number"
                      placeholder=""
                    />
                  </Form.Group>
                  <div className="tab-box-form address-group book-view">
                    <div className="form-input-content">
                      <Form.Group className="adress-book-view">
                        <Form.Label>Address*</Form.Label>
                      </Form.Group>
                      <Form.Group className="adress-book-field">
                        <div className="addon-box">
                          <span className="addon-tab-input">
                            <i className="icon-map-marker-form"></i>
                          </span>
                          <AddressSearch
                            locale="en_GB"
                            apiKey={process.env.GATSBY_LOQATE_API_KEY}
                            countries={["GB"]}
                            components={{ Input: AddressSearchInput }}
                            className="address-search-box"
                            inputClassname="address form-control"
                            listClassname="address-options"
                            listItemClassname="address-option"
                            onSelect={address => handleAddress(address)}
                          />
                        </div>
                      </Form.Group>
                      <Form.Text className="d-none">
                        or <Link href="#">Enter your address manually</Link>
                      </Form.Text>
                    </div>
                  </div>
                  <Button
                    variant="yellow"
                    onClick={() => Validatestep2()}
                    className="w-md-100 mtop-btn "
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="date-time" title="Date & Time" disabled={tabdisable}>
              <div className="tab-box-form calender-container">
                <CalendarBlock />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="valuationstep3">
        <div className="tab-box tab-box2 card-box">
          <div className="tab-box-form">
            <div className="cardbox-content">
              <ScrollAnimation
                animateOnce={true}
                duration={0.5}
                animateIn="fadeInDown"
              >
                <p>
                  Your request has been sent to our team and we will contact you
                  shortly to confirm your appointment and explain the valuation
                  process in more detail. Lorem ipsum dolor sit amet
                  consectetur.
                </p>
              </ScrollAnimation>
              <div className="what-to-do-text">
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <h6>What to do next?</h6>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                </ScrollAnimation>
              </div>
              <div className="tab-outline-buttons btn-outline-group2">
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <Link className="btn btn-outline" href="#" target="_blank">
                    <span>Find a Property</span>
                  </Link>
                </ScrollAnimation>
                <ScrollAnimation
                  animateOnce={true}
                  duration={0.5}
                  animateIn="fadeInUp"
                >
                  <Link
                    className="btn btn-outline mb-0"
                    href="#"
                    target="_blank"
                  >
                    <span>Contact Us</span>
                  </Link>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Steps
