import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
// import { markerImageSrc } from "./map-marker-image";
import { useLocation } from "@reach/router"
import { Scrollbars } from 'react-custom-scrollbars-2';
// import { useLocation } from "@reach/router"
import LoadExternalScript from "../utils/load-external-script";
import { markerImageSrc } from "./map-marker-image";
import classNames from 'classnames';
import { isIOS, isMobile, isMobileOnly, isTablet } from "react-device-detect"
const LocRatingMap = (props) => {  
      // const location = useLocation();
      // const currentUrl = location.href
      // var Lat = props.Latitude;
      // var Lng = props.Longitude;
      // var iframe_url = "https://schools.locrating.com/html5/plugin/schoolslist.aspx?&lat="+Lat+"&lng="+Lng+"&zoom=15&id=schoolslist_frame&maxschools=6&fullpath="+currentUrl+"";
      // return (
      //   <>
      //   <Scrollbars style={{ width: '', height: (props.LargeMonitor)?290:(props.ClientWidth)?410:290 }}>
      //   <iframe id="schoolslist_frame" src={iframe_url}></iframe>
      //   </Scrollbars>
      //   </>
      // )
      useEffect(()=>{
        setTimeout(function(){
        // loadLocratingPlugin({id:'schoolslist', lat: 51.456913, lng : -0.073099, type:'schoolslist'});}catch (err) {};
        try{window.loadLocratingPlugin({hidemenu:true, id:`schoolslist`,maxschools:"5",lat: props.Latitude, lng : props.Longitude, type:'schoolslist'});}catch (err) {}; 
      }, 1000);
  },[])   
  return (
    isMobileOnly ?
    <div id={`schoolslist`} className={"map_cnt"}></div> :
    <Scrollbars style={{ width: '', height: (props.LargeMonitor)?290:(props.ClientWidth)?410:290 }}>
    <div id={`schoolslist`} className={"map_cnt"}></div>
    </Scrollbars>
  )
}

export default LocRatingMap;