import React, { useState } from "react"
import ValuationPageBanner from "./ValuationStepsBanner/ValuationPageBanner"
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap"
import Steps from "./Steps/Steps"
import GoogleReviewValuationStep from "./GoogleReviewValuationStep/GoogleReviewValuationStep"
import BookingForm from "../../Components/forms/book-a-view-form"
import ReactMarkdown from "react-markdown/with-html"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import $ from "jquery"
import { BackArrow } from "../../Components/icons"
import { isIOS, isMobile } from "react-device-detect"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
const GETOFFICE = gql`
  query GetOfficeQuery($Phone: String!) {
    ourOffices(where: { Office_Phone: $Phone }) {
      Office_Email
      Office_Phone
    }
  }
`
const ValuationStepsComponent = props => {
  const ValuationBanner = props.Bgimg
  const myRef = React.createRef()
  const [Valuationform, setValuationform] = useState(true)
  const [valtype, setValtype] = useState("")
  const ShowValuationForm = valuationtype => {
    setValuationform(true)
    setValtype(valuationtype)
    $("html, body").animate({ scrollTop: 0 }, 500)
  }
  const backClick = value => {
    props.handlebackMobile(value)
  }
  const { loading, error, data } = useQuery(GETOFFICE, {
    variables: { Phone: props.property_office_phone },
  })
  if (loading) return <p className="d-none">Loading ...</p>
  var officeEmail = "sales@locationlocation.com"
  // if (data && data.ourOffices) {
  //   officeEmail = data.ourOffices[0]?.Office_Email
  // }
  if(props.office_crm_id === "19755bdf-cc89-4131-83c1-3065fdd44404"){
    officeEmail = "thetford@locationlocation.com"
  }
  if(props.property_type === "lettings"){
    officeEmail = "lettings@locationlocation.com, location-location.stoke-newington@post.agentresponse.co.uk"
    // officeEmail = "location-location.stoke-newington@post.agentresponse.co.uk" 
  }
  var subject = `Both Viewing - ${props.property_address}`;
  if(props.search_type === "lettings"){
    subject = `Letting Viewing - ${props.property_address}`;
  }else if(props.search_type === "sales"){
    subject = `Sales Viewing - ${props.property_address}`;
  }
  var apfEmail = ""
  if (
    props.property_office_email === "sn@locationlocation.com" &&
    props.property_type === "sales"
  ) {
    apfEmail = process.env.GATSBY_SN_SALES
  } else if (
    props.property_office_email === "sn@locationlocation.com" &&
    props.property_type === "lettings"
  ) {
    apfEmail = process.env.GATSBY_SN_LETTINGS
  } else if (
    props.property_office_email === "sn@locationlocation.com" &&
    props.property_type === "sales_lettings"
  ) {
    apfEmail = process.env.GATSBY_SN_GENERAL
  } else if (
    props.property_office_email === "andrew@locationlocation.com" &&
    props.property_type === "sales_lettings"
  ) {
    apfEmail = process.env.GATSBY_LLE_GENERAL
  } else if (
    props.property_office_email === "andrew@locationlocation.com" &&
    props.property_type === "sales"
  ) {
    apfEmail = process.env.GATSBY_LLE_SALES
  } else if (
    props.property_office_email === "andrew@locationlocation.com" &&
    props.property_type === "lettings"
  ) {
    apfEmail = process.env.GATSBY_LLE_LETTINGS
  }
  else {
    apfEmail = process.env.GATSBY_SN_SALES
  }
  return (
    <React.Fragment>
      <section
        className="valuation-main-landing valuation-steps-banner"
        style={{ backgroundImage: `url(${ValuationBanner})` }}
      >
        <div ref={myRef} />
        <div className="valuation-inner-wrapper">
          {isMobile && (
            <Container className="container-banner">
              <Row>
                <Col lg={12}>
                  <div className="mobile-back-view">
                    <a onClick={() => backClick("BackView")}>
                      <BackArrow />
                      <span>
                      Go backrr
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {Valuationform && (
            <div className="valuationstep1 valuationintrostep1">
              <ValuationPageBanner
                Heading={props.property_display_address}
                Title="Request a Viewing"
                Content="Please fill in the form below to make a viewing request. Please note, completing the form does not guarantee the viewing slot you requested."
              />
            </div>
          )}

          <div className="valuationstep3 valuationintrostep3">
            <ValuationPageBanner
              Heading="Thanks Chris. Now your preferred time and date."
              Title=""
              Content="&nbsp;"
            />
          </div>
          <div className="valuationthankyou valuationintrothankyou book-viewing">
            <ValuationPageBanner
              Heading="We’ll be in touch to confirm your booking."
              Title="Booking Confirmation"
              Content="&nbsp;"
            />
          </div>
          <Container>
            <Row>
              <Col xl={8} className="tab-column">
                {Valuationform && (
                  <div className="valuationform">
                    <BookingForm
                      to_email_id={officeEmail}
                      valuationtype={valtype}
                      Booking={props.Booking}
                      property_price={props.property_price}
                      property_type={props.property_type}
                      Bgimg={props.Bgimg}
                      property_address={props.property_address}
                      subject={subject}
                      apfEmail={apfEmail}
                      repeatId={props.repeatId}
                    />
                  </div>
                )}
                <div className="d-md-none">
                  <GoogleReviewValuationStep />
                </div>
              </Col>
            </Row>
            <Row className="d-none d-md-block">
              <Col xl={12}>
                <GoogleReviewValuationStep />
              </Col>
            </Row>
          </Container>
        </div>
        <div class="overlay-valuation-bg"></div>
      </section>
    </React.Fragment>
  )
}
export default ValuationStepsComponent