import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { IconPhone, PrintIcon } from "../../icons"
import Share from "./share"
import minilogo from "../../../images/logo-location.svg"
import minilogo1 from "../../../images/logo-location-yellow.svg"
import {
  ScrollingProvider,
  useScrollSections,
  useScrollSection,
  Section,
} from "react-scroll-section"
const PropertyHeader = props => {
  const bookClick = value => {
    props.handleBookClick2(value)
  }
  // History back
  const handleBack = () => {
    window?.history?.previous?.href()
  }
  var propurl = ""
  var listname = ""
  if (props.property_type == "sales") {
    propurl =
      "/property/for-sale/in-stoke-newington-and-hackney-and-north-london-and-east-london"
  }
  if (props.property_type == "lettings") {
    propurl =
      "/property/to-rent/in-stoke-newington-and-hackney-and-north-london-and-east-london"
  }
  const homeSection = useScrollSection("top")
  const detailSection = useScrollSection("detail")
  const floorplanSection = useScrollSection("floorplan")
  const mapSection = useScrollSection("map")
  const schoolSection = useScrollSection("school")
  const transportSection = useScrollSection("transport")
  const moreSection = useScrollSection("more")
  return (
    <div className="property-details-wrapper for-header-only">
      <header className={"open-navigation header-fix"}>
        <Container className="header-padding-mob container-header">
          <div className="menudesktop">
            <Link to="/">
              <img
                src={minilogo}
                onMouseOver={e => (e.currentTarget.src = minilogo1)}
                onMouseOut={e => (e.currentTarget.src = minilogo)}
                alt={"Logo"}
              />
            </Link>
            <div className="menubar-desktop">
              <ul id="detailsmenu">
                <li
                  onClick={homeSection.onClick}
                  selected={homeSection.selected}
                  className={homeSection.selected ? "active" : ""}
                >
                  <a>
                    <span className="closeslide-top">
                      <i className="arrow up"></i>
                      <br></br>Top
                    </span>
                  </a>
                </li>
                {/* <li data-menuanchor={`section-banner`}>                    
                            <a href={`#section-banner`}>
                            <span className="closeslide-top">
                            <i className="arrow up"></i><br></br>Top
                            </span>
                            </a>                    
                            </li> */}
                {/* <li data-menuanchor={`section-features`} className={'navfeatures active'}>
                                <a href="#section-features">Features</a>
                            </li> */}
                {/* <li className={'navdetails'}>                    
                            <Link to={propurl}
                    onClick={handleBack}>
                            <span className="closeslide-top">
                            <i className="arrow back"></i>Back
                            </span>
                            </Link>                    
                            </li> */}
                <li
                  onClick={detailSection.onClick}
                  selected={detailSection.selected}
                  className={detailSection.selected ? "active" : ""}
                >
                  <a>Details & Gallery</a>
                </li>
                {
                  props.property_floorplan[0] !== undefined && 
                  <li
                  onClick={floorplanSection.onClick}
                  selected={floorplanSection.selected}
                  className={floorplanSection.selected ? "active" : ""}
                >
                  <a>Floorplan</a>
                </li>
                }
                
                <li
                  onClick={mapSection.onClick}
                  selected={mapSection.selected}
                  className={mapSection.selected ? "active" : ""}
                >
                  <a>Map</a>
                </li>
                <li
                  onClick={schoolSection.onClick}
                  selected={schoolSection.selected}
                  className={schoolSection.selected ? "active" : ""}
                >
                  <a>Schools</a>
                </li>
                <li
                  onClick={transportSection.onClick}
                  selected={transportSection.selected}
                  className={transportSection.selected ? "active" : ""}
                >
                  <a>Transport</a>
                </li>
                <li
                  onClick={moreSection.onClick}
                  selected={moreSection.selected}
                  className={moreSection.selected ? "active" : ""}
                >
                  <a>More</a>
                </li>

                {/* <li data-menuanchor={`section-floorplan`} className={'navfloorplan'}>
                                <a href="#section-floorplan">Floorplan</a>
                            </li>
                            <li data-menuanchor={`section-map`} className={'navmap'}>
                                <a href="#section-map">Map</a>
                            </li>
                            <li data-menuanchor={`section-schools`} className={'navschools'}>
                                <a href="#section-schools">Schools</a>
                            </li>
                            <li data-menuanchor={`section-transport`} className={'navtransport'}>
                                <a href="#section-transport">Transport</a>
                            </li>
                            <li data-menuanchor={`section-more`} className={'navmore'}>
                                <a href="#section-more">More</a>
                            </li> */}
              </ul>
            </div>
            <div className="marketing-top-actions">
              <div className="phn-market">
                <IconPhone />
                <a href={`tel: ${props.property_office_phone}`}>
                  {props.property_office_phone}
                </a>
              </div>
              <ul className="list-inline">
                <li className="list-inline-item mr-4 d-none">
                  <a href="#">
                    <PrintIcon />
                  </a>
                </li>
                <li className="list-inline-item">
                  <Share
                    address_details={props.property_display_address}
                    id={props.id}
                    title={props.property_display_address}
                    price={props.property_price}
                    type={props.property_title}
                    telephone=""
                    images={props.property_images && props.property_images[0]}
                    bedrooms={props.property_bedroom}
                    bathrooms={props.property_bathroom}
                    property_office_phone={props.property_office_phone}
                  />
                </li>
              </ul>
              <a
                href="javascript:;"
                onClick={() => bookClick("BookaViewing")}
                class="btn btn-yellow"
              >
                Book a viewing
              </a>
            </div>
          </div>
        </Container>
      </header>
    </div>
  )
}
export default PropertyHeader
